import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MsgInfoService {
  private info: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private del: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor() { }
  subscribeInfo(): Observable<any> {
    return this.info.asObservable();
  }

  subscribeDelete(): Observable<any> {
    return this.del.asObservable();
  }
  
  updateInfo(data:any) {
    this.info.next(data);
  }

  updateDelete(data:any) {
    this.info.next(data);
  }
}
