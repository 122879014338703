import {Component, OnInit} from '@angular/core';
import {FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {NgFor, AsyncPipe} from '@angular/common';
import { DataStaffService } from 'src/app/services/data-staff.service';
import { jwtDecode } from "jwt-decode";
import { RefreshService } from 'src/app/services/refresh.service';
import { MsgInfoService } from 'src/app/services/msg-info.service';
@Component({
  selector: 'app-students-staff',
  templateUrl: './students-staff.component.html',
  styleUrls: ['./students-staff.component.css']
})
export class StudentsStaffComponent implements OnInit {
  
  classes = new Array();
  students = new Array();
  affectation:any;
  data:any;
  displayedColumns:any;
  selectables :any = {
  };
  constructor(
    private refreshService : RefreshService,
    private dataService : DataStaffService,
    private msgInfo : MsgInfoService
  ){
    
  
  }
  ngOnInit(): void {
      this.getData();
      this.refreshService.subscribeRefreshStudents().subscribe((res:any)=>{
        if(res){
          this.getData();
        }
      },(error)=>{
        this.msgInfo.updateInfo({ msg : "Une erreur s'est produite. Si le problème persite merci de contacter le service technique", duration : 4000});
      })
  }
  getData(){
    if(sessionStorage.getItem('USER-knu21-staff')){
      let token = sessionStorage.getItem('USER-knu21-staff')+"";
      let decode:any = jwtDecode(token);
      this.dataService.getStaffAffectations(decode.id).subscribe((res:any)=>{

        if(res){
          this.data = new Array()
          for(let k in  res.data){
            if( res.data[k].class && res.data[k].class.structCode && this.classes.indexOf(res.data[k].class.structCode)== -1 ){
              this.classes.push(res.data[k].class.structCode);
            }
          }
          if(this.classes.length > 0 ){
            for (let k in this.classes) {
              this.dataService.getStudentsByClass(this.classes[k]).subscribe((res:any)=>{
                
                this.data = this.data.concat(this.displayDataStudents(res.data)[0]);
                this.displayedColumns = this.displayDataStudents(res.data)[1];                
              })
            }    
          }
        }
      },(error)=>{
        this.msgInfo.updateInfo({ msg : "Une erreur s'est produite. Si le problème persite merci de contacter le service technique", duration : 4000});
      })
    }
  }

  modify(res: any) {
    
    if(res.msg == "student-absence"){
      let dateStart = this.formatDate2(res.data.Début);
      let dateEnd = this.formatDate2(res.data.Fin);
      let params = {
        "beginDate": dateStart.dateStr,
        "endDate": dateEnd.dateStr,
        "beginHour": dateStart.timeStr,
        "endHour": dateEnd.timeStr,
        "description": res.data.Commentaire,
        "documentJustificatif": res.data.Justificatif,
        "excuseCategory": "",
        "isRecurent": false,
        "passByLogia": res.data.Loge,
        "idStudent":res.id
      };

      this.dataService.planExcuse(params).subscribe((res)=>{
      this.msgInfo.updateInfo({ msg : "Excuse envoyé", duration : 1000});
      this.getData();
      
      },(error)=>{
        this.msgInfo.updateInfo({ msg : "Une erreur s'est produite. Si le problème persite merci de contacter le service technique", duration : 4000});
      })
    }
    if(res.msg == "student-commentaire"){
      let param = this.formatAnnotationParams(res.data, res.id);      
      this.dataService.addAnnotationsStudent(param).subscribe(
        (res:any)=>{
          this.msgInfo.updateInfo({msg:null});
          this.getData();
        },
        (error:any)=>{
          this.msgInfo.updateInfo({ msg : "Une erreur s'est produite", duration : 3000});
        }
      )
      
    }
  }
  formatAnnotationParams(data:any, id:any){
    let date = this.formatDate2(data.Date)
    return {
      "isPunishement": data.Punis,
      "detailInformation": data.Remarque,
      "level": data.Niveau,
      "date": date.dateStr,
      "hour": date.timeStr,
      "idStudent": id,
      "viewForParent" : data.Public,
    };
  }
  formatDate2(dateObj:any) {
    // Obtenir l'année, le mois, le jour, les heures, les minutes et les secondes de l'objet Date
    
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Les mois commencent à 0 en JavaScript
    const day = String(dateObj.getDate()).padStart(2, '0');
    const hours = String(dateObj.getHours()).padStart(2, '0');
    const minutes = String(dateObj.getMinutes()).padStart(2, '0');
    const seconds = String(dateObj.getSeconds()).padStart(2, '0');

    // Formater la date et le temps comme des chaînes de caractères
    const dateStr = `${year}${month}${day}`;
    const timeStr = `${hours}${minutes}${seconds}`;
        
    
    return { dateStr, timeStr };
  }
  
  displayDataStudents(data:any){
    let return_data = new Array();
    let colums = new Array(
      //'Ine',
      'Nom',
      'Prénom',
      'Statut de Scolarité',
      'Classe',
      'Niveau',
      'Chambre',
      'Remarques',
      'Absences',
      'Retards',
      'Départ',
    );
    
    if(data){
      for(let k = 0; k < data.students.length ; k++){
        return_data.push({
          //Ine: data[k].ine,
          Nom: data[k].name,
          Prénom: data[k].firstName,
          'Statut de Scolarité': this.returnStatut(data[k].status_pension),
          Classe: data[k].classPrincipal && data[k].classPrincipal[0] && data[k].classPrincipal[0].code ? data[k].classPrincipal[0].code : ''  ,
          Niveau: data[k].classPrincipal && data[k].classPrincipal[0] && data[k].classPrincipal[0].name ? data[k].classPrincipal[0].name : ''  ,
          Chambre: data[k].room,
          Remarques: data[k].annotations.length,
          Absences:  data[k].excuses.count,
          Retards:  data[k].retards.count,
          Départ:  this.addDateSeparator(data[k].date_leave),
          id: data.students[k].ine,
        });
      }
    }

    return new Array(return_data,colums);
  
  }
  returnStatut(str:any){

    if(str == 1){
      return 'Externe';
    }
    else if(str == 2){
      return 'Demi Pensionnaire';
    }
    else if(str == 29){
      return 'Demi Pensionnaire';
    }
    else if(str == 3){
      return 'Interne';
    }
    else if(str == 0){
      return 'Démissionnaire';
    }
    else{
      return 'ND';
    }
  }

  addDateSeparator(dateStr:any){
    if(dateStr>0){
      dateStr = dateStr.toString();
      return dateStr.slice(0, 4) + "-" + dateStr.slice(4, 6) + "-" + dateStr.slice(6);
    }
    else{
      return '';
    }
  }
}
