<div class="toolbar-container">
    <mat-toolbar>
      <button mat-icon-button (click)="showMenu = !showMenu" class="small-menu">
        <mat-icon>menu</mat-icon>
      </button>
      <div class="logotype">
        <div class="logo">
          <img src="/assets/img/logo.png" alt="logo">
        </div>
        <div class="nom">
          <span>Canu</span>
        </div>
      </div>

      <div class="menu-container-full standart-menu">
        <a routerLinkActive="active-link-1"  routerLink="/staff/affectations">Affectations</a>
        <a routerLinkActive="active-link-1"  *ngIf="isStaff" routerLink="/staff/students">Etudiants</a>
        <a routerLinkActive="active-link-1"  routerLink="/staff/msg">Messagerie</a>
      </div>
      <div class="dialog-close">
        <button (click)="logout()" >
            <mat-icon>exit_to_app</mat-icon>
        </button>
    </div>
    </mat-toolbar>
</div>
<nav [ngClass]="{'showMenu' : showMenu, 'hideMenu' : !showMenu}" class="small-menu">
    <div class="menu-container">
        <a routerLinkActive="active-link-2" (click)="showMenu = false"  routerLink="/staff/affectations">Affectations</a>
        <a routerLinkActive="active-link-2" *ngIf="isStaff" (click)="showMenu = false"  routerLink="/staff/students">Etudiants</a>
        <a routerLinkActive="active-link-2" (click)="showMenu = false"  routerLink="/staff/msg">Messagerie</a>
    </div>
    <div class="dialog-close">
      <button (click)="logout()" >
          <mat-icon>exit_to_app</mat-icon>
      </button>
  </div>
</nav>


  