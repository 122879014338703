import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';

import {LoginComponent} from './authentication/login/login.component';

import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {JwtInterceptor} from './helper/jwt-interceptor';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatDialogModule} from '@angular/material/dialog';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatSelectModule} from '@angular/material/select';
import {MatNativeDateModule} from '@angular/material/core';
import {MatTabsModule} from '@angular/material/tabs';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';



import {KeepOrderPipe} from './pipes/keep-oder.pipe';
import {StaffComponent} from './pages/staff/staff.component';
import {AffectationsStaffComponent} from './pages/staff/affectations-staff/affectations-staff.component';
import {StudentsStaffComponent} from './pages/staff/students-staff/students-staff.component';
import {HeaderStaffComponent} from './layout/header-staff/header-staff.component';
import {TableStaffComponent} from './template-staff/table-staff/table-staff.component';
import { TableStuComponent } from './template-staff/table-stu/table-stu.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {StudentDialogStaffComponent} from './template-staff/student-dialog-staff/student-dialog-staff.component';
import {CallDialogStaffComponent} from './template-staff/call-dialog-staff/call-dialog-staff.component';
import { FormDialogComponent } from './template-staff/form-dialog/form-dialog.component';
import { MessageDetailDialogComponent } from './template-staff/message-detail-dialog/message-detail-dialog.component';
import { MessagerieDetailDialogComponent } from './template-staff/messagerie-detail-dialog/messagerie-detail-dialog.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { DialogAddAbsenceComponent } from './template-staff/dialog-add-absence/dialog-add-absence.component';
import { MAT_DATE_LOCALE} from '@angular/material/core';
import { MsgComponent } from './pages/staff/msg/msg.component';

import { CustomDateAdapter } from './CustomDateAdapter';
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { NgxMatDateAdapter } from '@angular-material-components/datetime-picker';
import { AddAbsenceComponent } from './template/add-absence/add-absence.component';

registerLocaleData(localeFr);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    KeepOrderPipe,
    StaffComponent,
    AffectationsStaffComponent,
    StudentsStaffComponent,
    HeaderStaffComponent,
    TableStaffComponent,
    TableStuComponent,
    StudentDialogStaffComponent,
    CallDialogStaffComponent,
    FormDialogComponent,
    MessageDetailDialogComponent,
    MessagerieDetailDialogComponent,
    MsgComponent,
    DialogAddAbsenceComponent,
    AddAbsenceComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    BrowserAnimationsModule,
    MatSortModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    NgbModule,
    MatSnackBarModule,
    MatDatepickerModule,
    MatSelectModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    MatTabsModule,
    MatCheckboxModule,
    MatRadioModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    { provide: NgxMatDateAdapter, useClass: CustomDateAdapter },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
