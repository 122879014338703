import { Component,Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataStaffService } from 'src/app/services/data-staff.service';
import { MsgInfoService } from 'src/app/services/msg-info.service';
import { MatDialogRef } from '@angular/material/dialog';
import {MatDialog} from '@angular/material/dialog';
import { FormDialogComponent } from '../form-dialog/form-dialog.component';

@Component({
  selector: 'app-call-dialog-staff',
  templateUrl: './call-dialog-staff.component.html',
  styleUrls: ['./call-dialog-staff.component.css']
})
export class CallDialogStaffComponent implements OnInit{
  today_ts =  Date.now();
  countAbs=0;
  countRet=0;
  data:any;
  previous_data:any;
  allRetard = false;
  allPresent = false;
  typeOrder = false;
  isStaff = true;
  studentsList = new Array();
  students =new Array();
  constructor(    
    private dialog:  MatDialog,
    private dataService : DataStaffService,
    @Inject(MAT_DIALOG_DATA) public inject: any,
    private msgInfo : MsgInfoService,
    public dialogRef: MatDialogRef<CallDialogStaffComponent>,

  ){

    this.init();
    
  }
  init(){
    
    if(this.data){
      this.previous_data = this.data;
    }
    this.dataService.getCall(this.inject.id).subscribe((res:any)=>{
      this.data = res.data[0];
      
      
      this.data.details.sort((a:any, b:any) => {
        if (a.student.name < b.student.name) {
            return -1;
        }
        if (a.student.name > b.student.name) {
            return 1;
        }
        return 0;
      });
      this.data.details.forEach((el:any) => {
        if(el.isRetarted){
          this.countRet++;
        }
        else if(el.isPresent === false){
          this.countAbs++;
        }  
      });

      this.initSavedCall();
      this.initAllPresences();
      this.initAllRetards();
      if(!this.data.isClose){
        this.restoreStatutStudent();
      }

      if(localStorage.getItem("KNU-21-call-order")){
        let el:any = localStorage.getItem("KNU-21-call-order");
        this.typeOrder = JSON.parse(el);
        this.setOrder();
      }
      
    },(error)=>{
      this.msgInfo.updateInfo({ msg : "Une erreur s'est produite. Si le problème persite merci de contacter le service technique", duration : 4000});
    })
    this.dataService.getStudents().subscribe((res:any)=>{
      this.studentsList = res.data;
      this.updateStudentsList();
    })
  }
  restoreStatutStudent(){

    if(!this.previous_data){
      this.previous_data = this.data;
    }
    this.previous_data.details.forEach((el:any) => {
      this.data.details.forEach((el2:any) => {
        if(el.student.ine == el2.student.ine){
          el2.isPresent = el.isPresent;
          el2.isRetarted = el.isRetarted;
        }
      });
    });
  }
  ngOnInit(): void {
    this.isStaff = this.dataService.isStaff();
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  setOrder(){
    if(!this.typeOrder){
      this.data.details.sort((a:any, b:any) => {
        if (a.student.name < b.student.name) {
            return -1;
        }
        if (a.student.name > b.student.name) {
            return 1;
        }
        return 0;
      });
    }
    else{
      this.data.details.sort((a:any, b:any) => {
        if (a.student.numInternatRoom < b.student.numInternatRoom) {
            return -1;
        }
        if (a.student.numInternatRoom > b.student.numInternatRoom) {
            return 1;
        }
        return 0;
      });
    }
    localStorage.setItem("KNU-21-call-order", JSON.stringify(true))
  }
  setAllRetards(bool:boolean){
    for(let k in this.data.details){
      if(this.data.details[k]['isPresent']){
        this.data.details[k]['isRetarted'] = bool;
      }
    }
    if(bool){
      this.countRet = this.data.details.length;
    }
    else{
      this.countRet = 0;
    }
  }
  countAllPresents(){
    let count = 0;
    for(let k in this.data.details){
      if(this.data.details[k]['isPresent']){
        count++;
      }
    }
    return count;
  }
  setAllPresences(bool:boolean){
    for(let k in this.data.details){
      this.data.details[k]['isPresent'] = bool;
    }
    if(bool){
      this.countAbs = 0;
    }
    else{
      this.countAbs = this.data.details.length;
    }
  }
  initAllRetards(){
    for(let k in this.data.details){
      if(this.data.details[k]['isRetarted'] === true){
        this.countRet++;
      }
      else if(this.data.details[k]['isRetarted'] === null){
        this.data.details[k]['isRetarted'] =false;
      }
    }
  }
  initAllPresences(){
    for(let k in this.data.details){
      if(this.data.details[k]['isPresent'] === false){
        this.countAbs++;
      }
      else if(this.data.details[k]['isPresent'] === null){
        this.data.details[k]['isPresent'] =false;
        this.countAbs++;
      }
    }
  }
  initSavedCall(){
    let savedCall = sessionStorage.getItem("call-"+this.inject.id);
    if(savedCall){
      this.data = JSON.parse(savedCall);
    }
  }
  saveCall(){
    sessionStorage.setItem("call-"+this.inject.id,JSON.stringify(this.data));
  }
  updateCountRet(bool:boolean){
    if(bool){
     this.countRet++;
    }
    else{
      this.countRet--;
    }
  }
  updateCountAbs(bool:boolean){
    if(bool){
      this.countAbs++;
     }
     else{
       this.countAbs--;
     }
  }
  forceRetarded(el:any){
    if(!el.isPresent){
      el.isRetarded = el.isPresent;
    }
  }
  updateStudentsList(){
    this.students = new Array();
    
    for (let k in this.studentsList) {
      let add = true;
      const stu = this.studentsList[k];
      for(let i = 0; i < this.data.details.length; i++){
        const el = this.data.details[i].student;
        if(el.ine == stu.ine){
          add = false;
          break;
        }
      }
      if(add){
        this.students.push({id :  stu["ine"], name : stu["firstName"]+" "+stu["name"] })
      }
    }
    this.students.sort((a:any, b:any) => {
      if (a.name < b.name) {
          return -1;
      }
      if (a.name > b.name) {
          return 1;
      }
      return 0;
    });
  }
  openDialogAddStudent(){

    let selectables = {
      'Liste Etudiants' : this.students
    }
    const dialogRef = this.dialog.open(FormDialogComponent,{
      width: '550px',
      height: '80vh',
      data: {
        data : selectables,
        title : "Ajout d'élève(s)",
        type : "edit"
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if(res["Liste Etudiants"]){
        res["Liste Etudiants"].forEach((v:any) => {
          this.dataService.addStudentToClasse(this.inject.structCode,v).subscribe((res:any)=>{            
            this.init();
          },(error)=>{
            this.msgInfo.updateInfo({ msg : "Une erreur s'est produite. Si le problème persite merci de contacter le service technique", duration : 4000});
          })
        });
      }
    });
  }
}
