import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { jwtDecode } from "jwt-decode";

@Injectable({
  providedIn: 'root'
})
export class DataStaffService {
  private userSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(private http: HttpClient) {}
  
  isStaff(){
    const token = sessionStorage.getItem('USER-knu21-staff')+'';
    if(token){
      const decodedToken :any = jwtDecode(token);
      
      if(decodedToken && (decodedToken["Role"] == "SURVEILLANT_"+environment.establishment || decodedToken["Role"] == "CPE_"+environment.establishment)){
        return true;
      }
      else{
        return false;
      }
    }
    else{
      return false;
    }

  }
  // GET DATA
  
  getStaffAffectations(id:string){
    return this.http.get(`${environment.apiUrl}/api/Staff/${id}/affectation`);
  }
  
  getStudentsByClass(id:string){    
    return this.http.get(`${environment.apiUrl}/api/Class/${id}/students`)
  }
  
  getStudents(){    
    return this.http.get(`${environment.apiUrl}/api/Student`)
  }
  
  addStudentToClasse(idClass:string, idStudent:string){
    return this.http.post(`${environment.apiUrl}/api/Class/incorporate/${idClass}/${idStudent}`,{});
  }
  
  callEnd(id:string){    
    return this.http.post(`${environment.apiUrl}/api/Call/end/${id}`,{})
  }

  callStart(id:string){    
    return this.http.post(`${environment.apiUrl}/api/Call/begin/${id}`,{})
  }

  getCall(id:string){    
    return this.http.get(`${environment.apiUrl}/api/Call/${id}`)
  }

  updateCall(id:string, data:any){    
    return this.http.post(`${environment.apiUrl}/api/Call/ls/${id}`,data)
  }
  updateCallIntervenant(id:string, data:any){    
    return this.http.post(`${environment.apiUrl}/api/Call/endlist/${id}`,data)
  }

  getStudent(id:string){    
    return this.http.get(`${environment.apiUrl}/api/Student/${id}`)
  }
  getStudentMissing(id:string){    
    return this.http.get(`${environment.apiUrl}/api/Stu/${id}/missing`)
  }
  getStudentImg(id:string){    
    return this.http.get(`${environment.apiUrl}/api/Student/profil/${id}`)
  }
  getStudentTutor(id:string){    
    return this.http.get(`${environment.apiUrl}/api/Student/${id}/tutor`)
  }
  getStudentClass(id:string){    
    return this.http.get(`${environment.apiUrl}/api/Student/${id}/class`)
  }
  getStudentAnnotations(id:string){    
    return this.http.get(`${environment.apiUrl}/api/Student/${id}/annotations`)
  }
  
  getStudentExcuses(id:string){    
    return this.http.get(`${environment.apiUrl}/api/Excuse/${id}`)
  }
  addAnnotationsStudent(data:any){    
    return this.http.post(`${environment.apiUrl}/api/Student/add/annotation`,data)
  }

  getStaffs(){
    return this.http.get(`${environment.apiUrl}/api/Staff/all`);
  }
  uploadJustificatif(idStudent:string,idExcuse:string,formData:any){    
    return this.http.post(`${environment.apiUrl}/api/Excuse/${idStudent}/${idExcuse}/upload`,formData);
  }
  
  planExcuse(data:any){    
    return this.http.post(`${environment.apiUrl}/api/Excuse/add`,data)
  }
  refuseExcuse(id:string){
    return this.http.post(`${environment.apiUrl}/api/Student/excuse/validate/${id}`, {})
  }
  validateExcuse(id:string){
    return this.http.post(`${environment.apiUrl}/api/Student/excuse/refuse/${id}`, {})
  }
}
