import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RefreshService {
  private userSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(private http: HttpClient) {}
  
  subscribeRefreshStudents(): Observable<any> {
    return this.userSubject.asObservable();
  }
  
  RefreshStudent() {
    this.userSubject.next(true);
  }  
}
