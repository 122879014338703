
<div class="dialog" *ngIf="data && data.details">
    <div class="exit">
        <mat-icon (click)="onNoClick()">clear</mat-icon>
    </div>
    <div class="title-container"> 
        <div >
            <div class="flex-container">
                <div class="class">
                    {{inject.classe}}
                </div>
            </div>
            <div class="orderBy">
                <span> Trier par Eleves</span>
                <mat-slide-toggle [(ngModel)]="typeOrder" (change)="setOrder()"></mat-slide-toggle>
                <span> par Chambre </span>
            </div>
        </div>
        <div>
            <div class="flex-container">
                <p>{{data.details.length-countAbs}}/{{data.details.length}} élève{{data.details.length > 1 ? 's' :''}}</p>
            </div>
            <div class="flex-container">
                <p>{{countRet}} retard{{countRet > 1 ? 's' :''}}</p>
            </div>
        </div>

        <div class="time">
            <div class="date">{{inject.date}}</div>
            <div class="hour">{{inject.heure}}</div>
        </div>
    </div>
    <div class="btn-small-container" *ngIf="!isStaff">
        <button class="btn-small" (click)="openDialogAddStudent()">Ajout d'élève(s)</button>
    </div>
    <div class="students-container">
        <div class="table-container">
            <table>
                <thead>
                    <tr>
                        <th>Chambre</th>
                        <th>Eleve</th>
                        <th>Présent <mat-slide-toggle [(ngModel)]="allPresent" (change)="setAllPresences(allPresent);saveCall()"></mat-slide-toggle> </th>
                        <th>En retard <mat-slide-toggle [(ngModel)]="allRetard" [disabled]="countAllPresents() == 0" (change)="setAllRetards(allRetard);saveCall()"></mat-slide-toggle> </th>
                        <th>Commentaire</th>
                        <th>Information</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="student" *ngFor="let el of data.details">
                        <td>
                            <div  style="font-size: 12px;">{{el.student.numInternatRoom}}</div>
                        </td>
                        <td class="nom">
                            <div style="font-size: 12px; font-weight: bold;">{{el.student.name}}</div> 
                            <div  style="font-size: 12px;">{{el.student.firstname}}</div>
                        </td>
                        <td class="sliders">
                            <mat-slide-toggle [(ngModel)]="el.isPresent" (change)="updateStudentsList();forceRetarded(el);updateCountAbs(!el.isPresent);saveCall()"></mat-slide-toggle>
                        </td>
                        <td>
                            <mat-slide-toggle [disabled]="!el.isPresent"  [(ngModel)]="el.isRetarted" (change)="updateStudentsList();updateCountRet(el.isRetarted);saveCall()"></mat-slide-toggle>
                        </td>
                        <td>
                            <input type="text" [(ngModel)]="el.messageSup">                       
                        </td>
                        <td>
                            {{el.messageSupSup}}
                        </td>
                        <td>
                            <app-add-absence [ine]="el.student.ine" (dataChanged)="init()"></app-add-absence>
                        </td>

                    </tr>
                </tbody>
               </table>
 
        </div>    
        <div class="btn-container">
            <button class="btn" [mat-dialog-close]="data">Valider l'appel</button>
         </div>   
    </div>
</div>