import { Component,Inject, ViewChild,ElementRef,AfterViewInit,Renderer2  } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatAutocompleteSelectedEvent, MatAutocompleteModule} from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatSelect } from '@angular/material/select';
import { DataStaffService } from 'src/app/services/data-staff.service';
import { MsgInfoService } from 'src/app/services/msg-info.service';
@Component({
  selector: 'app-form-dialog',
  templateUrl: './form-dialog.component.html',
  styleUrls: ['./form-dialog.component.css'],
})
export class FormDialogComponent implements AfterViewInit{
  separatorKeysCodes: number[] = [ENTER, COMMA];
  fichierPDF:any;
  selectables:any;
  default_data:any;
  ret_data:any = {};
  title:string;
  dateSelected:any;
  filtered:any={};
  default_hour = 17;
  @ViewChild('fruitInput') fruitInput!: ElementRef<HTMLInputElement>;
  @ViewChild(MatSelect) matSelect!: MatSelect;
  
  constructor(    
    @Inject(MAT_DIALOG_DATA) public data: any,
    private renderer: Renderer2,
    private dataservice : DataStaffService,
    private msgInfo : MsgInfoService,
  ){
    
    this.selectables = this.data.data;
    this.default_data = this.data.default_data;
    this.title = this.data.title;
    for(let k in this.selectables){
      if (this.default_data && (this.default_data[k] || this.default_data[k] === false) ){
        this.ret_data[k+''] = this.default_data[k];
      }else if(this.selectables[k]==="boolean"){
        this.ret_data[k+''] = false;
      }
      else if(this.selectables[k]==="date"){
        if(!this.ret_data[k+''] ){
          this.ret_data[k+''] = new Date();
          this.ret_data[k+''].setHours(this.default_hour, 0, 0, 0)
        }
      }
      else if(this.selectables['Liste Etudiants']){
        this.filtered['Liste Etudiants'] = this.selectables['Liste Etudiants'];
      }
      else{
        this.ret_data[k+''] = <any>'';
      }
      
    }
  };

  ngAfterViewInit(): void {
    if(this.matSelect){
      this.matSelect.openedChange.subscribe(isOpened => {
        if (isOpened) {
          const overlayPane = document.querySelector('.mat-select-panel');
          if (overlayPane) {
            this.renderer.listen(overlayPane, 'keydown', (event: KeyboardEvent) => {
              // Désactiver les touches de navigation
              const navigationKeys = ['ArrowUp', 'ArrowDown', 'Enter', 'Space'];
              if (navigationKeys.includes(event.key)) {
                event.preventDefault();
                event.stopPropagation();
              }
            });
          }
        }
      });
    }
    
  }
  preventDefault(event: any){
    event.preventDefault(); 
    event.stopPropagation();
  }
  filter(k:string, event:any){
    event.stopPropagation();
     if(event.target.value!=''){
      this.filtered[k] = this.selectables[k].filter((item:any) => 
        item.name.toLowerCase().includes(event.target.value.toLowerCase())
       );
     }
     else{
      this.filtered[k] = this.selectables[k];
     }
  }
  convertObjectToArray(obj: { [key: string]: string }): { id: string, name: string }[] {
    return Object.keys(obj).map(key => ({ id: key, name: obj[key+''] }));
  }
  remove(row: any, k:string): void {
    const index = this.ret_data[k+''].indexOf(row);
    
    if (index >= 0) {
      this.ret_data[k+''].splice(index, 1);
    }
  }
  add(event: MatChipInputEvent,k:string): void {
    const value = (event.value || '').trim();
    
    // Add our fruit
    if (value) {
      this.ret_data[k+''].push(value);
    }

    // Clear the input value
    event.chipInput!.clear();
    this.fruitInput.nativeElement.value = '';
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    // this.fruits.push(event.option.viewValue);
    // this.fruitInput.nativeElement.value = '';
    // this.fruitCtrl.setValue(null);
  }

  
  selectionChanged(e:any){    
  }
  isString(str:any){
    return  typeof str === 'string' || str instanceof String;
  }
  checkDate(e:any, col:any, el:any){       
    if(e.key != "Backspace" && e.key !="ArrowLeft" && e.key !="ArrowRight"){
      let str = e.target.value.replace(/[^0-9]/g, '');
      e.target.value = str;
      e.target.value = this.formatDate(e.target.value);

    }
  }
  formatDate(str:string) {
      // Vérifier si la chaîne est de la longueur attendue

  
      // Extraire les parties de la date et de l'heure
      var annee = str.substr(0, 4);
      var mois = str.substr(4, 2);
      var jour = str.substr(6, 2);
      var heure = str.substr(8, 2);
      var minute = str.substr(10, 2);
      var seconde = str.substr(12, 2);
  
      // Retourner la chaîne formatée
      
      let ret = "";
      if(annee){
        ret += annee;
      }
      if(mois){
        ret += "/"+mois;
      }
      if(jour){
        ret += "/"+jour;
      }
      if(heure){
        ret += " "+heure;
      }
      if(minute){
        ret += ":"+minute;
      }
      if(seconde){
        ret += ":"+seconde;
      }
      return ret;
 
  }
  check(d:any){
    let bool = true;    
    for(let k in d){
      if(d[k]=== '' && k!="Justificatif"){
        bool = false;
        break;
      }
    }
    return bool;
  }
  onFileSelected(event: any, key:any) {
    this.fichierPDF = event.target.files[0];
    this.submitForm(key);
  }

  async submitForm(key:any) {
    if (this.fichierPDF) {
      try {
        const blobString = await this.pdfToBlob(this.fichierPDF);
        this.ret_data[key] = blobString.replace(/^data:application\/pdf;base64,/, ''); ;
      } catch (error) {
        console.error('Erreur lors de la lecture du fichier PDF :', error);
      }
    } else {
      console.error('Aucun fichier PDF sélectionné.');
    }
  }
  
  private pdfToBlob(fichier: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      
      reader.onload = () => {
        const blobString = reader.result as string;
        resolve(blobString);
      };

      reader.onerror = () => {
        reject(reader.error);
      };
      
      reader.readAsDataURL(fichier);
    });
  }
  apply2End(s:any){
    if(s == "Début" && this.ret_data["Fin"] == ""){
      this.ret_data["Fin"] = this.ret_data["Début"];
    }
    if(s == "Fin" && this.ret_data["Début"] == ""){
      this.ret_data["Début"] = this.ret_data["Fin"];
    }
  }

  changeDate(e:any, key:any){

    let parts = e.target.value.split(/[\s/:]/);

    let day = parseInt(parts[0]);
    let month = parseInt(parts[1]) - 1;
    let year = parseInt(parts[2]);
    let hours = parseInt(parts[3]);
    let minutes = parseInt(parts[4]);
    let seconds = parseInt(parts[5]);
      
    this.ret_data[key] = new Date(year, month, day, hours, minutes, seconds);
    
  }
}
  
