import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataStaffService } from 'src/app/services/data-staff.service';

@Component({
  selector: 'app-header-staff',
  templateUrl: './header-staff.component.html',
  styleUrls: ['./header-staff.component.css']
})

export class HeaderStaffComponent implements OnInit {
  showMenu = false;
  isStaff = false;
  constructor(    
    private router : Router,
    private dataStaffService : DataStaffService
  ){
  }
  ngOnInit(): void {
    this.isStaff = this.dataStaffService.isStaff();
  }
  logout(){
    sessionStorage.removeItem('USER-knu21-staff');
    this.router.navigate(['login']);
  }
}
