<div mat-dialog-actions class="dialog-close">
    <button [mat-dialog-close]="true">
        <mat-icon>clear</mat-icon>
    </button>
</div>

<div class="wrapperStu" *ngIf="true">
    <div class="headerStu">
        <div class="infoStu">
            <div class="stu">
                <div class="pic">
                    <img *ngIf="imgStudent" [src]="imgStudent">
                </div>
            </div>
            <div class="infos"  *ngIf="dataStudent">
                <div class="name">{{dataStudent.name}} {{dataStudent.firstname}}</div>            
                <div class="name">{{dataStudent.libRegime}} </div>            
            </div>
        </div>
    </div>

    <mat-tab-group>
        <mat-tab label="Contact"> 
            <div class="tab-wrapper">
                <div class="left">
                    <div class="infos" *ngIf="dataStudent">
                        <div  class="classPrincipal title">  Classe Principale</div>
                        <div *ngFor =" let c of dataStudent.classPrincipal" class="classPrincipal"> {{c.code}} </div>
                        <div class="otherClass title"> Autre classes et clubs </div>
                        <div *ngFor =" let c of dataStudent.otherClass" class="otherClass"> {{c.code}} </div>
                    </div>
                </div>
                <div class="right">
                    <form action="">
                        <label for="message">Envoyer un message</label>
                        <textarea id="message" ></textarea>
                        <input type="submit" value="valider" class="submit">
                    </form>
                </div>
            </div>
           
        </mat-tab>
        <mat-tab label="Absences"> 
            <div class="absences-wrapper">
                <app-table-stu
                [data]="dataAbsences" 
                [displayedColumns]="displayedColumns" 
                [title] ="''" 
                [type]="'asbences'" 
                [typeTable] = "'-mini'"
                [selectables]="selectables2"
                (tableChanged)="modify2($event)"></app-table-stu>
            </div>
        </mat-tab>
        <mat-tab label="Remarques"> 
            <div class="absences-wrapper">
                <app-table-stu
                [data]="annotationStudent" 
                [displayedColumns]="displayedColumns2" 
                [title] ="''" 
                [type]="'remarques'" 
                [typeTable] = "'-mini'"
                [selectables]="selectables"
                (tableChanged)="modify($event)"></app-table-stu>
            </div>
        </mat-tab>
        <mat-tab label="Retards"> 
            <div class="absences-wrapper">
                <app-table-stu
                [data]="dataRetards" 
                [displayedColumns]="displayedColumns3" 
                [title] ="''" 
                [type]="'retard'" 
                [typeTable] = "'-mini'"
                [selectables]="selectables"
                (tableChanged)="modify($event)"></app-table-stu>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>



