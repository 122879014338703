import { Component,Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataStaffService } from 'src/app/services/data-staff.service';
import {jwtDecode} from 'jwt-decode';
import { MsgInfoService } from 'src/app/services/msg-info.service';
import { RefreshService } from 'src/app/services/refresh.service';

@Component({
  selector: 'app-student-dialog-staff',
  templateUrl: './student-dialog-staff.component.html',
  styleUrls: ['./student-dialog-staff.component.css']
})
export class StudentDialogStaffComponent implements OnInit {
  
  dataStudent:any;
  dataClass:any;
  dataTutor:any;
  dataAbsences:any;
  displayedColumns3:any;
  dataRetards:any;
  displayedColumns2:any;
  displayedColumns:any;
  authorizedActions = [false,false,false] // delete,add,edit
  annotationStudent:any;
  imgStudent:any;
  selectables :any = {
    Date : "date",
    Punis : "boolean",
    Remarque : "textarea",
    Niveau : { 1: "positif", 2: "neutre", 3: "négatif"},  
  }
  selectables2 :any = {
    Début : "date",
    Fin : "date",
    Commentaire: "text",
    Justificatif : "file",
    Loge : "boolean"  
  };
  
  constructor(    
    private msgInfo : MsgInfoService,
    private refreshService : RefreshService,
    private dataService : DataStaffService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ){
  }
  ngOnInit(): void {
    if(sessionStorage.getItem('USER-knu21-staff')){
      this.refreshAnnotations(this.data.id);
      this.refreshExcuses(this.data.id);
      this.dataService.getStudentImg(this.data.Ine).subscribe((res:any)=>{
        if(res && !res.count){
          const blobString = res;
          const byteCharacters = atob(blobString);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blobImage = new Blob([byteArray], { type: 'image/png' });
          this.imgStudent = URL.createObjectURL(blobImage);
        }
      })

      this.dataService.getStudent(this.data.id).subscribe((res:any)=>{        
        if(res && res.content){
          
          this.dataStudent = res.content;
          if(this.dataStudent.retards && this.dataStudent.retards && this.dataStudent.retards.length > 0){
            let dataTable = this.displayDataRetard(this.dataStudent.retards);
            this.dataRetards = dataTable[0];
            this.displayedColumns3 = dataTable[1];
          }
          else{
            this.dataRetards = [];
            this.displayedColumns3 = ['no data'];
          }
  
        }
      },(error)=>{
        this.msgInfo.updateInfo({ msg : "Une erreur s'est produite. Si le problème persite merci de contacter le service technique", duration : 4000});
      })


    }  
  }
  displayDataRetard(data:Array<any>){
    let return_data = new Array();
    let colums = new Array(
      'Début',
      'Fin',
    );
    
    if(data){
      for(let k = 0; k < data.length ; k++){
        return_data.push({
          Début:  this.formatDate(data[k].affectation.beginDate,data[k].affectation.timeBegin).dateStr,
          Fin:  this.formatDate(data[k].affectation.endDate,data[k].affectation.timeEnd).dateStr,
          endDate:data[k].affectation.endDate,
          endHour:data[k].affectation.endHour,
          beginDate:data[k].affectation.beginDate,
          beginHour:data[k].affectation.beginHour,
        });
      }
    }
    return new Array(return_data,colums);
  
  }
  refreshExcuses(id:any) {
    this.dataService.getStudentExcuses(id).subscribe((res:any)=>{
      if(res.data && res.data.excuses && res.data.excuses.length > 0){
        let dataTable = this.displayDataMissing(res.data.excuses);
        this.dataAbsences = dataTable[0];
        this.displayedColumns = dataTable[1];
      }
      else{
        this.dataAbsences = [];
        this.displayedColumns = ['no data'];
      }
    },(error)=>{
      this.msgInfo.updateInfo({ msg : "Une erreur s'est produite. Si le problème persite merci de contacter le service technique", duration : 4000});
    })
    this.refreshService.RefreshStudent();

  }
  refreshAnnotations(id:any){
    this.dataService.getStudentAnnotations(id).subscribe((res:any)=>{
      if(res.data && res.data.length > 0){
        let dataTable = this.displayDataAnnotation(res.data);
        this.annotationStudent = dataTable[0];
        this.displayedColumns2 = dataTable[1];
      }
      else{
        this.annotationStudent = [];
        this.displayedColumns2 = ['no data'];
      }
      this.refreshService.RefreshStudent();
    },(error)=>{
      this.msgInfo.updateInfo({ msg : "Une erreur s'est produite. Si le problème persite merci de contacter le service technique", duration : 4000});
    })
  }
  formatDate(dateNumber:number, timeNumber:number){

    const year = Math.floor(dateNumber / 10000);
    const month = Math.floor((dateNumber % 10000) / 100);
    const day = dateNumber % 100;
    
    const hours = Math.floor(timeNumber / 10000);
    const minutes = Math.floor((timeNumber % 10000) / 100);
    const seconds = timeNumber % 100;
    
    const formattedDate = `${year}/${('0' + month).slice(-2)}/${('0' + day).slice(-2)}`;
    const formattedTime = `${('0' + hours).slice(-2)}:${('0' + minutes).slice(-2)}:${('0' + seconds).slice(-2)}`;
    

    const date = {
     date : new Date(year, month-1, day, hours, minutes, seconds),
     dateStr : formattedDate + " " + formattedTime,
    }  

    return date;
}
  
  formatDate2(dateObj:any) {
    // Obtenir l'année, le mois, le jour, les heures, les minutes et les secondes de l'objet Date
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Les mois commencent à 0 en JavaScript
    const day = String(dateObj.getDate()).padStart(2, '0');
    const hours = String(dateObj.getHours()).padStart(2, '0');
    const minutes = String(dateObj.getMinutes()).padStart(2, '0');
    const seconds = String(dateObj.getSeconds()).padStart(2, '0');

    // Formater la date et le temps comme des chaînes de caractères
    const dateStr = `${year}${month}${day}`;
    const timeStr = `${hours}${minutes}${seconds}`;
    
    return { dateStr, timeStr };
  }
  displayDataMissing(data:Array<any>){
    let return_data = new Array();
    let colums = new Array(
      'Début',
      'Fin',
      'Justificatif',
    );
    
    if(data){
      for(let k = 0; k < data.length ; k++){
        let debut = this.formatDate(data[k].beginDate,data[k].beginHour);
        let fin =this.formatDate(data[k].endDate,data[k].endHour) ;
        return_data.push({
          Début:  debut.dateStr,
          Fin:  fin.dateStr,
          Justificatif: data[k].justificatif,
        });
      }
    }
    
    return new Array(return_data,colums);
  
  }
  displayDataAnnotation(data:Array<any>){
    let return_data = new Array();
    let colums = new Array(
      'Date',
      'Niveau',
      'Remarque',

    );
    
    if(data){
      for(let k = 0; k < data.length ; k++){
        return_data.push({
          Date:  this.formatDate(data[k].date,data[k].hour).dateStr,
          Niveau: data[k].level,
          Remarque:  data[k].detailInformation,
        });
      }
    }

    return new Array(return_data,colums);
  
  }
  formatAnnotationParams(data:any,id:any){
    let date = this.formatDate2(data.Date)
    return {
      "isPunishement": data.Punis,
      "detailInformation": data.Remarque,
      "level": data.Niveau,
      "date": date.dateStr,
      "hour": date.timeStr,
      "idStudent": id
    };
  }
  modify(data: any) {
    
    this.msgInfo.updateInfo({msg : "Loading ...", duration : 99999})
    if(data.msg == "create" && data.data){
      let param = this.formatAnnotationParams(data.data,this.dataStudent.ine);      
      this.dataService.addAnnotationsStudent(param).subscribe(
        (res:any)=>{
          this.msgInfo.updateInfo({msg:null});
          this.refreshAnnotations(this.data.id);
        },(error)=>{
          this.msgInfo.updateInfo({ msg : "Une erreur s'est produite. Si le problème persite merci de contacter le service technique", duration : 4000});
        }
      )
      
    }
    else if(data.msg == "edit" && data.data){

    }
    else if(data.msg == "delete" && data.data){
    }
  }
  modify2(res: any) {
    
    if(res.msg && res.msg == "create"){
      let dateStart = this.formatDate2(res.data.Début);
      let dateEnd = this.formatDate2(res.data.Fin);
      let params = {
        "beginDate": dateStart.dateStr,
        "endDate": dateEnd.dateStr,
        "beginHour": dateStart.timeStr,
        "endHour": dateEnd.timeStr,
        "description": res.data.Commentaire,
        "documentJustificatif": res.data.Justificatif,
        "excuseCategory": "",
        "isRecurent": false,
        "passByLogia": res.data.Loge,
        "idStudent": this.dataStudent.ine
      };

      this.dataService.planExcuse(params).subscribe((res2)=>{
        this.msgInfo.updateInfo({ msg : "Excuse envoyé", duration : 1000});
        this.refreshExcuses(this.dataStudent.ine);
      },(error)=>{
        this.msgInfo.updateInfo({ msg : "Une erreur s'est produite. Si le problème persite merci de contacter le service technique", duration : 4000});
      })
    }
    else if(res.msg && res.msg == "upload"){
      this.uploadPdf(res.data.documentJustificatif, res.data.idExcuse, this.dataStudent.ine)
    }
  }
  base64ToBlob(base64: string, contentType: string): Blob {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: contentType });
  }
  uploadPdf(base64String:string, idExcuse:string, id:any): void {
    const base64Data = base64String.split(',')[1]; // Supprimer le préfixe
    const contentType = 'application/pdf'; // Type MIME
    const pdfBlob = this.base64ToBlob(base64Data, contentType);
    
    const formData = new FormData();
    formData.append('file', pdfBlob, 'justificatif.pdf');
    
    this.dataService.uploadJustificatif(id,idExcuse, formData).subscribe(
      (res) => {
        this.msgInfo.updateInfo({ msg : "Upload réussi", duration : 1000});
      },(error)=>{
        this.msgInfo.updateInfo({ msg : "Une erreur s'est produite. Si le problème persite merci de contacter le service technique", duration : 4000});
      }
    );
  }

}
