import {
  HttpRequest,
  HttpInterceptor,
  HttpHandler,
  HttpEvent
} from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { AuthenticationService } from "../services/authentication.service";
import { Router } from '@angular/router';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthenticationService, 
    private router : Router,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    
    let currentToken = sessionStorage.getItem("USER-knu21-staff");
  
    if (!currentToken || this.authService.isTokenExpired(currentToken)) {
      // Si le token est expiré, redirige vers la page de login
      this.router.navigate(['/staff/login']);
    }

    if (
      currentToken && !request.url.includes('/api/Login/refresh') && !request.url.includes('/admin/login')
    ) {


      if (request.url.includes('/api/Login')) {
        
        return next.handle(request);
      }
      else{
            
        this.authService.refreshToken(currentToken).subscribe((res:any)=>{
          sessionStorage.setItem("USER-knu21-staff", res.token);
          currentToken = res.token;
        })
            request = request.clone({
              setHeaders: {
                Authorization: `Bearer ${currentToken}`
              }
            });
            return next.handle(request);
      }    
    }
    return next.handle(request);
  }
}
