import { Component, AfterViewInit, ViewChild,SimpleChanges,OnChanges,Input,Output,EventEmitter } from '@angular/core';
import {MatSort, MatSortModule} from '@angular/material/sort';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import { DataStaffService } from 'src/app/services/data-staff.service';
import { StudentDialogStaffComponent } from '../student-dialog-staff/student-dialog-staff.component';
import { CallDialogStaffComponent } from '../call-dialog-staff/call-dialog-staff.component';
import { MsgInfoService } from 'src/app/services/msg-info.service';
import { MessageDetailDialogComponent } from '../message-detail-dialog/message-detail-dialog.component';
import { MessagerieService } from 'src/app/services/messagerie.service';
import { FormDialogComponent } from '../form-dialog/form-dialog.component';
import { jwtDecode } from 'jwt-decode';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-table-staff',
  templateUrl: './table-staff.component.html',
  styleUrls: ['./table-staff.component.css']
})
export class TableStaffComponent {

  @ViewChild(MatSort) sort!: MatSort;
  @Input() displayedColumns!: any;
  @Input() data!: any;
  @Input() title!: string;
  @Input() type!: string;
  @Input() selectables!: any;
  @Input() typeTable!: any;
  @Output() tableChanged = new EventEmitter<any>();
  
  
  dataSource: any;
  filter :any;
  today_ts =  Date.now();
  startDay:any;
  endDay:any;
  checkAll = false;
  isIntervenant=false;
  showMenu = false;
  hide_filter = true;
  constructor(    
    private dialog:  MatDialog,
    private dataStaffService : DataStaffService,
    private messagerie : MessagerieService,
    private msgInfo : MsgInfoService,
  ){
    this.startDay = this.getMidnightTimestamp();
    this.endDay = this.getEndOfDayTimestamp();
    let decoded = <any>jwtDecode(sessionStorage.getItem("USER-knu21-staff") || "");
    if(decoded.Role == "INTERVENANT_"+environment.establishment ){
      this.isIntervenant = true;
    }
    else{
      this.isIntervenant = false;
    }

  }
  
    
  checkAllFilterdLines(bool:boolean){
    this.dataSource.filteredData.forEach((el:any) => {
      el.check = bool;
    });
  }

  generateFilter(){
    this.filter = {};
    if(this.displayedColumns && this.displayedColumns.length>0){
      for(let i=0 ;i<this.displayedColumns.length;i++){
        if(this.displayedColumns[i] !="action" && this.displayedColumns[i]!="checked"){
          this.filter[this.displayedColumns[i]] = "";
        }
      }
    }
  }
  getMidnightTimestamp() {
    const now = new Date();
    const midnight = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
    return midnight.getTime();
  }

  getEndOfDayTimestamp() {
    const now = new Date();
    const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999);
    return endOfDay.getTime();
  }

  formatData(data:any){
    if(this.type =="affectation"){
      let ret = new Array();
      for(let k in data){
        let fin = this.convertToTimestamp(data[k].Fin);
        let debut = this.convertToTimestamp(data[k].Début);
        if(
          (debut > this.startDay && debut < this.endDay ) || (fin > this.startDay && fin < this.endDay )
        ){
          ret.push(data[k]);
        }
      }
      return ret;
    }
    else{
      return data;
    }
  
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.init();
  }
  init(){
    if(this.data){
      if(!this.filter){
        this.generateFilter();
      }
      this.data = this.formatData(this.data);
      this.dataSource = new MatTableDataSource(this.data);
      this.dataSource.sort = this.sort;
      this.dataSource.filterPredicate = this.createFilter();
    }
  }
  applyFilter() {
    this.dataSource.filter = this.filter;
  }
  createFilter() {
    let filterFunction = function (data: any, filter:any): boolean {
      let isFilterSet = false;
      for (const col in filter) {
        if (filter[col].toString() !== '') {
          isFilterSet = true;
        }
      }
      
      
      let nameSearch = () => {
        let found = true;
        if (isFilterSet) {
          for (const col in filter) {
            if(col !="actions" && col !="checked"){
              filter[col].trim().toLowerCase().split(' ').forEach((word:any) => {
                if (!(data[col].toString().toLowerCase().indexOf(word) != -1 && isFilterSet)) {
                  found = false
                }
              });
            }
          }
          return found
        } else {
          return true;
        }
      }
      return nameSearch()
    }
    return filterFunction
  }
  openStudentDialog(id:string) {
    const dialogRef = this.dialog.open(StudentDialogStaffComponent,{
      data: {
        id : id,
      },
      width:"100vw"
    });
  }
  openCallDialog(row:any) {
    const dialogRef = this.dialog.open(CallDialogStaffComponent,{
      data: {
        id : row.id,
        classe : row.Classe,
        structCode : row.structCode,
        date : row.Début.split(" ")[0], 
        heure : row.Début.split(" ")[1]
      }
    });
    dialogRef.afterClosed().subscribe((ret:any) => {
      let ret_array = new Array();
      if(ret && !this.isIntervenant){
        for(let k in ret.details){
          ret_array.push(
            {
              "ineStudent": ret.details[k].iDstudent,
              "isPresent": ret.details[k].isPresent,
              "isRetarded": ret.details[k].isRetarted,
              "info": ret.details[k].messageSup
            }
          )
        }     
        
           
        this.dataStaffService.callStart(ret.id).subscribe((res1:any)=>{
          this.msgInfo.updateInfo({msg :"Mise à jour des présences", duration : -1})
          this.dataStaffService.updateCall(ret.id, ret_array).subscribe((res2:any)=>{
            this.dataStaffService.callEnd(ret.id).subscribe((res3:any)=>{
              this.msgInfo.updateInfo({msg:null});
              row.isClose=true;
              sessionStorage.removeItem("call-"+row.id);
            },(error)=>{
              this.msgInfo.updateInfo({ msg : "Une erreur s'est produite. Si le problème persite merci de contacter le service technique", duration : 4000});
            })
          },(error)=>{
            this.msgInfo.updateInfo({ msg : "Une erreur s'est produite. Si le problème persite merci de contacter le service technique", duration : 4000});
          })
        },(error:any)=>{
          this.msgInfo.updateInfo({ msg : "Une erreur s'est produite. Si le problème persite merci de contacter le service technique", duration : 4000});
        })
      }
      else if(ret && this.isIntervenant){
        for(let k in ret.details){
          ret_array.push(
            {
              "ine": ret.details[k].iDstudent,
              "isPresent": ret.details[k].isPresent,
              "isLate": ret.details[k].isRetarted,
              "leaveDef": !ret.details[k].isPresent,
              "idCall": ret.id
            }
          )
        }  
        
          this.dataStaffService.updateCallIntervenant(ret.id, ret_array).subscribe((res2:any)=>{
            sessionStorage.removeItem("call-"+row.id);
          },(error:any)=>{
            this.msgInfo.updateInfo({ msg : "Une erreur s'est produite. Si le problème persite merci de contacter le service technique", duration : 4000});
          })
      }
      
    });
  }
  viewMsg(data:any){
    let dialogRef = this.dialog.open(MessageDetailDialogComponent,{
      width: '550px',
      height: '80vh',
      data: data,
    });
    dialogRef.afterClosed().subscribe(res => {
      if(res && res.to){
        if(this.type =="MsgNoRead"){
          this.tableChanged.emit({msg:'refresh&sendMsg', data : res});
        }
        else{
          this.tableChanged.emit({msg:'sendMsg', data : res});
        }
      }
      else if(this.type =="MsgNoRead"){
        this.messagerie.setRead(data.id).subscribe((res)=>{
          this.tableChanged.emit({msg:'refreshMsg', data : res});
        },(error)=>{
          this.msgInfo.updateInfo({ msg : "Une erreur s'est produite. Si le problème persite merci de contacter le service technique", duration : 4000});
        })
      }
    });
  }
  timeCheck(row:any){
    if(row.Fin && this.type =="affectation" ){
      if(row.callDetail.isClose){
        return "green-alert";
      }
      else{
        if(this.convertToTimestamp(row.Fin) < this.today_ts){
          return "red-alert";
        }
        else if(this.convertToTimestamp(row.Début) < this.today_ts){
          return "orange-alert";
        }
        else{
          return "blue-alert";
        }
      }
      
    }
    else{
      return "";
    }   
  }
  
  callCheck(row:any){
    if(row.Fin){
      if(this.convertToTimestamp(row.Fin) + 3600*1000 < this.today_ts ){
        return false;
      }
      else{
        return true;
      }
    }
    else{
      return false;
    }
  }
  
  convertToTimestamp(dateString:string) {
    const date = new Date(dateString);
    return date.getTime();
  }
  addAbsence(el:any){
    let selectables:any = {
       Début : "date",
       Fin : "date",
       Commentaire: "text",
       Justificatif : "file"    
     };
     const dialogRef = this.dialog.open(FormDialogComponent,{
       width: '550px',
       height: '80vh',
       data: {
         data : selectables,
         title : "Ajouter une absence ",
         type : "edit"
       }
     });
     dialogRef.afterClosed().subscribe(res => {
       if(res){
         this.tableChanged.emit({msg:'student-absence', data:res, id: el.id});
       }
     });
   }
   addAbsences(){
    let ine_array = new Array();
    this.dataSource.data.forEach((el:any) => {
      if(el.check){
        ine_array.push(el.Ine);
      }
    });

    let selectables:any = {
       Début : "date",
       Fin : "date",
       Commentaire: "text",
       Justificatif : "file",
       Loge : "boolean"    
     };
     const dialogRef = this.dialog.open(FormDialogComponent,{
       width: '550px',
       height: '80vh',
       data: {
         data : selectables,
         title : "Ajouter une absence ",
         type : "edit"
       }
     });
     dialogRef.afterClosed().subscribe(res => {
       if(res){
        ine_array.forEach((el:any)=>{
          this.tableChanged.emit({msg:'student-absence', data:res, id: el});
        })
       }
     });
   }
   addAnnotation(el:any){    
     let selectables :any = {
       Date : "date",
       Punis : "boolean",
       Remarque : "textarea",
       Niveau : { 1: "positif", 2: "neutre", 3: "négatif"},  
       Public : "boolean",
     }
     const dialogRef = this.dialog.open(FormDialogComponent,{
       width: '550px',
       height: '80vh',
       data: {
         data : selectables,
         title : "Ajouter un commentaire",
         type : "edit"
       }
     });
     dialogRef.afterClosed().subscribe(res => {
       if(res){
         this.tableChanged.emit({msg:'student-commentaire', data:res, id: el.id});
       }
     });
   }
}
