import { Component,Input,Output,EventEmitter } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { FormDialogComponent } from 'src/app/template-staff/form-dialog/form-dialog.component';
import { DataStaffService } from 'src/app/services/data-staff.service';
import { MsgInfoService } from 'src/app/services/msg-info.service';

@Component({
  selector: 'app-add-absence',
  templateUrl: './add-absence.component.html',
  styleUrls: ['./add-absence.component.css']
})
export class AddAbsenceComponent {
 @Input() ine!:any;
 @Output() dataChanged = new EventEmitter<any>();
 constructor(
  private dialog:  MatDialog,
  private dataService:DataStaffService,
  private msgInfo : MsgInfoService,
 ){
 }
 addAbsence(){
  let selectables:any = {
     Début : "date",
     Fin : "date",
     Commentaire: "text",
     Justificatif : "file",
     "Sortie Loge" : "boolean",    
     "Entrée Loge" : "boolean",  
      Origine : {
      0 : "Application",
      1 : "Email",
      2 : "Téléphone",
      3 : "Courrier",
      4 : "Signature",
     },
     Récurrent : "boolean"
   };
   const dialogRef = this.dialog.open(FormDialogComponent,{
     width: '550px',
     height: '80vh',
     data: {
       data : selectables,
       title : "Ajouter une absence ",
       type : "edit"
     }
   });
   dialogRef.afterClosed().subscribe((res:any) => {
     if(res){
      console.log(res);
      
      let dateStart = this.formatDate2(res.Début);
      let dateEnd = this.formatDate2(res.Fin);
      let params = {
        "beginDate": dateStart.dateStr,
        "endDate": dateEnd.dateStr,
        "beginHour": dateStart.timeStr,
        "endHour": dateEnd.timeStr,
        "description": res.Commentaire,
        "documentJustificatif": res.Justificatif,
        "excuseCategory": "",
        "isRecurent": false,
        "passByLogiaLeave": res["Sortie Loge"] ? res.Début : null ,
        "passByLogiaEntry": res["Entrée Loge"] ? res.Fin : null ,
        "idStudent": this.ine,
        "originCanal": parseInt(res.Origine),
        "isRecurrent": res.Récurrent,
      };

      this.dataService.planExcuse(params).subscribe((res:any)=>{
        this.msgInfo.updateInfo({ msg : "Excuse envoyé", duration : 1000});
        this.dataChanged.emit({msg:'student-absence-updated'});

      },(error:any)=>{
        this.msgInfo.updateInfo({ msg : "Une erreur s'est produite. Si le problème persite merci de contacter le service technique", error: error, duration : 4000});
      })
     }
   });
 }
 formatDate2(dateObj:any) {
  // Obtenir l'année, le mois, le jour, les heures, les minutes et les secondes de l'objet Date
  
  const year = dateObj.getFullYear();
  const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Les mois commencent à 0 en JavaScript
  const day = String(dateObj.getDate()).padStart(2, '0');
  const hours = String(dateObj.getHours()).padStart(2, '0');
  const minutes = String(dateObj.getMinutes()).padStart(2, '0');
  const seconds = String(dateObj.getSeconds()).padStart(2, '0');
  
  // Formater la date et le temps comme des chaînes de caractères
  const dateStr = `${year}${month}${day}`;
  const timeStr = `${hours}${minutes}${seconds}`;

  return { dateStr, timeStr };
 }
}
