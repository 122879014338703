<div class="page-container" [hidden]="!data">
    
      
      <div>
        <div class="title-container">
          <div class="title"> 
            <h1 *ngIf="title">{{title}}</h1>
          </div>
        </div>
        <div class="resetFilter" (click)="generateFilter(); applyFilter()">
          réinitialisation des filtres
        </div>
          <div class="table-row">
            <div  class="table-container{{typeTable}} col-12" [hidden] = "!dataSource" >
              <table mat-table [dataSource]="dataSource" matSort  matSortDisableClear matSortDirection="desc">
            
                <ng-container *ngFor ="let col of displayedColumns">
                  <ng-container matColumnDef="{{col}}">
  
                    <th mat-header-cell *matHeaderCellDef mat-sort-header id="{{col}}"> 
                        <ng-container *ngIf="col != 'actions' && col != 'check'">
                          <div class="flex-container">
                            <div class="col">
                              {{col}}
                            </div>
                            <div class="filter" *ngIf="type != 'Msg' && type != 'MsgRecep' && type != 'MsgNoRead'">
                              <input matInput [(ngModel)]="filter[col]" placeholder="filter sur {{col}}" #input (keyup)="$event.stopPropagation(); applyFilter()" (click)="$event.stopPropagation()">
                            </div>
                          </div>
                        </ng-container>
                    
                        <ng-container *ngIf="col == 'actions' && type == 'affectation'">Appel</ng-container>
                        <ng-container *ngIf="col == 'actions' && type != 'affectation'">
                          <div class="mat-mini-icon-container" *ngIf="type=='student'" (click)="$event.stopPropagation(); addAbsences()" title ="ajouter des absences">
                            <mat-icon class = "red">access_time</mat-icon>
                            <span class="mobile-hide red"> Ajout d'absences </span>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="col == 'check'"> 
                          <div>
                            <div>Sélectionner</div>
                            <div class="small-click" (click)="$event.stopPropagation(); checkAllFilterdLines(true)">Tout sélectionner</div>
                            <div class="small-click" (click)="$event.stopPropagation(); checkAllFilterdLines(false)">Tout désélectionner</div>
                          </div>
                        </ng-container>  
                    </th>
  
                    <td mat-cell *matCellDef="let row" class="{{timeCheck(row)}}"> 
                      <ng-container *ngIf="col !='actions' && col !='check'"> 
                        <ng-container>
                          {{ col == 'Message' ? (row[col] | slice:0:25)+'...' : row[col] }}
                        </ng-container>
                      </ng-container>  
                      
                      
                      
                      <ng-container *ngIf="col == 'actions'">  
                        <div class="actions">
                          <div *ngIf="type == 'student'" class="clickable default-color ml15"  (click)="$event.stopPropagation(); addAbsence(row)">
                            <mat-icon class="red">access_time</mat-icon>
                          </div>
                          <div *ngIf="type == 'student'" class="clickable default-color ml15"  (click)="$event.stopPropagation(); openStudentDialog(row.id)">
                            <mat-icon>more_horiz</mat-icon>
                          </div>
                          <div *ngIf="type == 'student'" class="clickable default-color ml15"  (click)="$event.stopPropagation(); addAnnotation(row)">
                            <mat-icon>comment</mat-icon>
                          </div>
                          <div *ngIf="type == 'affectation' && callCheck(row)"  class="clickable"  (click)="$event.stopPropagation(); openCallDialog(row)">
                            <mat-icon>notifications</mat-icon>
                          </div>
                          <div class="mat-mini-icon-container" *ngIf="type == 'Msg' || type == 'MsgRecep' || type == 'MsgNoRead'"  (click)="viewMsg(row)">
                            <mat-icon>mail_outline</mat-icon>
                          </div>
                        </div>
                      </ng-container>  
                      <ng-container *ngIf="col == 'check'">
                        <mat-checkbox [(ngModel)]="row['check']" (click)="checkAll=true"></mat-checkbox>
                      </ng-container> 
                    </td>
                  </ng-container>
                </ng-container>
                
               
              
            
            
            
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <ng-container *ngIf="type == 'student' ">
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr> 
                </ng-container>
                <ng-container *ngIf="type != 'student' ">
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
                </ng-container>
                
                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="4">Aucune données corresponds au(x) filtre(s)</td>
                </tr>
              </table>
              
            </div>
            
    
    
            
    
          </div>
    
    
    
          
          <div class="progress-container" [hidden] = "dataSource">
            <mat-progress-spinner mode="indeterminate" diameter="50" color="#1e5995" strokeWidth="4"></mat-progress-spinner>
          </div>
      </div>
  
  </div>
  <div class ="loader" [hidden]="data">
    <mat-spinner></mat-spinner>
  </div>